@import "shared";

.highlight {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
    transition: clip-path 0.2s;
    pointer-events: none;
}

[data-highlight-container] {
    @include hover {
        .highlight {
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
    }
}
