@import "shared";

$height-bp: 800px;
$nav-size: calc(var(--col-size) * 5.5);
$mobile-logo-size: px(60);
$mobile-nav-col-size: px(43.9);

.Header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    @include z-index(Header);
    @include grid;
    @include gpu;
    align-items: flex-start;
    justify-content: flex-start;
}

.inner {
    grid-column: 1 / #{$grid-columns};
    transform: translateX(var(--col-size-half)) translateY(var(--col-size-half));
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: var(--header-height);
    position: relative;

    .isActive & {
        background-color: $off-white;
    }

    @include bp(mobile) {
        grid-column: 1 / #{$grid-columns-mobile};
        display: flex;
    }
}

.innerLine {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $black;
    transform: scaleX(0);
    transform-origin: right center;
    z-index: 2;

    &.innerLine1 {
        top: 0;
    }

    &.innerLine2 {
        bottom: 0;
    }
}

.leftSide {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.logoContainer {
    background-color: $black;
    width: px(80);
    margin-right: px(23);
    position: relative;
}

.logoContainerInner {
    @include position-100(absolute);

    width: px(80);
    height: px(80);
    @include flex-center;

    @include bp(mobile) {
        width: $mobile-logo-size;
        height: $mobile-logo-size;
    }
}

.logoContainerInnerBg {
    @include position-100(absolute);
    background-color: $black;
    transform: scaleY(0);
    transform-origin: bottom center;
    border-bottom-right-radius: px(2);
    border-top-left-radius: px(2);
}

.logo {
    color: $off-white;
    width: 79.7%;
    z-index: 2;
    position: relative;
}

.descriptionLines {
    @include body-small;
    height: var(--header-height);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-top: px(2);

    @include bp(mobile) {
        display: none;
    }
}

.descriptionLine1 {
    border-bottom: 1px solid $black;
    opacity: 0;
}

.descriptionLine2 {
    opacity: 0;
}

.rightSide {
    height: 100%;
    @include flex-center;

    @include bp(mobile) {
    }
}

.social {
    @include flex-center;
    height: 100%;
}

.socialSvgContainer {
    opacity: 0;
}

.socialLine {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: $black;
    transform: scaleY(0);
    transform-origin: top center;

    &.socialLineRight {
        left: auto;
        right: 0;
    }
}

.socialLink {
    height: 100%;
    min-width: var(--header-height);
    max-width: var(--header-height);
    @include flex-center;
    position: relative;

    @include hover {
        background-color: $yellow;
    }

    svg {
        display: block;
        max-width: px(15);
        width: 100%;
    }
}

.navTopLevelLinks {
    height: 100%;
    width: $nav-size;
    @include flex-center;
    @include eyebrow;
    font-weight: 400;
    text-transform: uppercase;
    overflow: hidden;
    position: relative;

    @include bp(mobile) {
        width: px(165);
    }
}

.menuButton {
    height: 100%;
    @include reset-button;
    width: 40%;
    background-color: $off-white;
    @include flex-center;
    height: 100%;
    color: $black;
    transform: translateY(101%);

    @include hover {
        background-color: $green;
    }

    @include bp(mobile) {
        width: 50%;
    }

    .navIsOpen & {
        background-color: $green;
    }
}

.topLevelLink {
    @include flex-center;
    height: 100%;
    width: 60%;
    background-color: $black;
    color: $white;
    transform: translateY(101%);

    @include hover {
        background-color: lighten($black, 10%);
    }

    @include bp(mobile) {
        width: 50%;
    }
}

/* =======================================
Navigation
======================================= */

.nav {
    position: fixed;
    top: calc(var(--col-size-half) + var(--header-height));
    right: var(--col-size-half);
    width: $nav-size;
    @include z-index(Nav);
    height: calc(
        100% - calc(var(--col-size-half) + var(--header-height)) -
            var(--col-size-half)
    );
    @include gpu;
    pointer-events: none;

    &.navIsOpen {
        overflow: auto;
    }

    @include bp(mobile) {
        width: calc(100% - #{$mobile-logo-size} - var(--col-size) + 1px);
    }
}

.navBg {
    background-color: $off-white;
    border: 1px solid $black;
    border-top: none;
    @include position-100(absolute);
    width: 100%;
    transform: scaleY(0);
    z-index: 2;
}

.navMiddleLine {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    left: var(--col-size);
    background-color: $black;
    transform: scaleY(0);
    z-index: 5;

    @include bp(mobile) {
        left: #{$mobile-nav-col-size};
    }
}

.navInner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    height: 100%;
    z-index: 2;
}

.navGridLine {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: $black;
    z-index: 2;
    transform: translateX(-101%);
}

.navGridContainer {
    display: grid;
    grid-template-columns: minmax(0, var(--col-size)) 1fr;
    width: 100%;
    position: relative;
    overflow: hidden;

    @include bp(mobile) {
        grid-template-columns: minmax(0, #{$mobile-nav-col-size}) 1fr;
    }

    &.navHeader {
        pointer-events: none;
        min-height: px(16);

        .navGridContainerItem {
            padding: px(3);
            font-size: px(8);
            font-weight: 200;
            text-transform: uppercase;

            &:nth-child(2) {
                opacity: 0;
            }
        }
    }
}

.navGridContainerItem {
    @include body;
    text-transform: uppercase;
    white-space: nowrap;
    padding: px(13) px(3);
    position: relative;
    overflow: hidden;
    opacity: 0;

    @media screen and (max-height: $height-bp) {
        padding: px(8) px(3);
    }

    &:nth-child(2) {
        opacity: 1;
    }

    @include bp(mobile) {
        padding: px(17) px(10);
    }
}

.navLinkLabel {
    position: relative;
    display: inline-block;

    &:after {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $black;
        height: 1px;
        transform: scale(0);
        transform-origin: left center;
        transition: transform 0.2s;
    }
}

.navGridContainer {
    @include hover {
        .navGridContainerItem {
            &:nth-child(2) {
                background-color: $yellow;
                border-left: 1px solid $black;

                .cross {
                    display: block;
                }
            }

            &:last-child {
                text-decoration: underline;
            }
        }

        .navLinkLabel {
            &:after {
                transform: scale(1);
            }
        }
    }
}

.cross {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 110%;
    display: none;
}

.lottieContainer {
    position: absolute;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.navLinks {
    display: block;
    width: 100%;
}

.countdownContent {
    padding-left: var(--col-size);
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include bp(mobile) {
        padding-left: #{$mobile-nav-col-size};
    }
}

.countdownText {
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    padding: 0 px(10) 0 calc(var(--col-size) + #{px(10)});
    text-align: center;
    font-weight: 700;
    opacity: 0;

    @include bp(mobile) {
        padding: 0 px(15) 0 calc(#{$mobile-nav-col-size} + #{px(15)});
    }
}

.countdown {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    @include body;

    & > span {
        position: relative;
        opacity: 0;

        &:not(:last-child) {
            &:after {
                content: ":";
                display: block;
                right: px(-5);
                top: 45%;
                transform: translateY(-50%);
                position: absolute;
            }
        }
    }
}

.downloadAppContainer {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.downloadAppContainerBg {
    @include position-100(absolute);
    background-color: $red;
    transform: scaleY(0);
    transform-origin: bottom center;
}

.downloadAppContainerInner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: px(30) 0;

    @media screen and (max-height: $height-bp) {
        padding: px(15) 0;
    }

    @include bp(mobile) {
        padding: px(20) 0;
    }
}

.downloadAppText {
    margin-bottom: auto;
    color: $white;
    text-align: center;
    font-weight: 700;
    opacity: 0;

    @include bp(mobile) {
        padding: 0 px(20);
    }
}

.downloadAppButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    margin-top: px(10);
}

.downloadButtonWrapper {
    &:not(:last-child) {
        margin-bottom: px(5);
    }
}

.downloadAppButtonImage {
    width: px(125);
    height: unset;
}

.footer {
    height: px(30);
    font-size: px(8);
    color: $white;
    text-transform: uppercase;
    padding: 0 px(5);
    align-self: flex-end;
    width: calc(100% - var(--col-size));
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    overflow: hidden;

    @include bp(mobile) {
        width: calc(100% - #{$mobile-nav-col-size});
        height: px(60);
    }
}

.footerBg {
    @include position-100(absolute);
    background-color: $black;
    transform: translateX(-100%);
    z-index: 1;
}

.footerLink {
    opacity: 0;
    z-index: 2;
    position: relative;
    display: block;
}

.navSideText {
    position: absolute;
    bottom: var(--col-size-half);
    left: calc(var(--col-size-half) + #{px(2.5)});
    font-size: px(8);
    transform-origin: left center;
    transform: rotate(-90deg);
    opacity: 0;

    @include bp(mobile) {
        left: calc(var(--col-size-half) + #{px(7)});
    }
}
