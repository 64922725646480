@import "layout.scss";

// Variables
//
// Add global variables here, color palette, those variables shared by components all applied to global styling

// ====================================================================
// Breakpoints
//
// Window breakpoints
// ====================================================================
$layout-mobile: map-get($layout, mobile) + px;
$layout-tablet: map-get($layout, tablet) + px;
$layout-laptop: map-get($layout, laptop) + px;
$layout-desktop: map-get($layout, desktop) + px;
$layout-xl: map-get($layout, xl) + px;

// ====================================================================
// Base palette
//
// Grayscale and brand colors for use across the site.
// ====================================================================
$white: #fff;
$black: #1a1a1a;
$off-white: #ebe9dc;
$green: #b1c736;
$yellow: #ffb800;
$blue: #1962a7;
$blue-light: #6c98aa;
$red: #be4226;
$grey-1: #99998f;

// ====================================================================
// General
//
// Global color variables, used usually in sections
// Global variables used across the web app
// ====================================================================
$page-bg-color: $white;
$border-radius: px(60);
$border-radius-mobile: px(20);
$section-padding: px(80);
$section-padding-mobile: px(80);

// ====================================================================
// Header
// ====================================================================
$header-height: px(40);
$header-height-mobile: px(30);

// ====================================================================
// Grid
// ====================================================================
$grid-gutter: 0;
$grid-columns: 32;
$grid-columns-mobile: 16;

// ====================================================================
// Body Fonts
// ====================================================================
$atlas-typewriter-font-family: "Atlas Typewriter";
$display-fonts: $atlas-typewriter-font-family, Helvetica, Arial, sans-serif;
$body-fonts: $atlas-typewriter-font-family, Helvetica, Arial, sans-serif;

// ====================================================================
// List of z-index element
// ====================================================================
$elements: GridBackground, Main, VideoThumbnail, FixedNavigationCta,
    FixedNavigationCtaText, SpeakerHoverImage, Header, Nav, IntroLoader, Cookies,
    VideoPopout;
