@import "shared";

.VerticalText {
    width: var(--col-size-half);
    text-transform: uppercase;
    @include body-small;
    font-weight: 700;
    position: absolute;
    top: 0;
    text-align: center;
    color: $black;
    z-index: 5;
    opacity: 0;
    @include vh(100);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.is-left {
        left: 0;
    }

    &.is-right {
        right: 0;
    }
}

.inner {
}

.VerticalText__char {
    display: block;
    white-space: break-spaces;
}
