@import "shared";

$offset: px(17);

:export {
    offset: $offset;
}

.IntroLoader {
    @include gpu;
    @include vh(100);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    @include z-index(IntroLoader);

    --distance: #{$offset};
    --box-size: #{px(400)};

    &.visiblyHidden {
        opacity: 0;
    }

    @include bp(mobile) {
        --box-size: #{px(250)};
    }
}

.boxContainer {
    width: var(--box-size);
    height: var(--box-size);
    position: absolute;

    & > div {
        @include position-100(absolute);
    }

    .boxFront {
        left: calc(var(--distance) * -1);
        bottom: calc(var(--distance) * -1);
        top: auto;
        z-index: 5;
        opacity: 0.99; // Safari ios jaggy box glitch fix
    }

    .boxLeftSide {
        z-index: 2;
        width: calc(var(--distance) * 2);
        left: calc(var(--distance) * -1);
        transform: skewY(-45deg);
        overflow: hidden;
        opacity: 0.99; // Safari ios jaggy box glitch fix
    }

    .boxRightSide {
        z-index: 4;
        width: calc(var(--distance) * 2);
        left: calc(100% - var(--distance));
        transform: skewY(-45deg);
        overflow: hidden;
        opacity: 0.99; // Safari ios jaggy box glitch fix
    }

    .boxBackSide {
        left: var(--distance);
        bottom: var(--distance);
        top: auto;
        z-index: 1;
        opacity: 0.99; // Safari ios jaggy box glitch fix
    }

    .boxMiddle {
        z-index: 3;
        // background-color: $off-white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.topBoxLine,
.bottomBoxLine {
    position: absolute;
    left: 0;
    width: 100%;
    height: px(2);
    background-color: $black;
    transform: translateX(-100%);

    @include bp(mobile) {
        height: px(1.5);
    }
}

.topBoxLine {
    top: 0;
}

.bottomBoxLine {
    bottom: 0;
}

.logoContainer {
    width: 80%;
    position: relative;
    z-index: 3;
}

.logoBoxBg {
    @include position-100(absolute);
}

.logo {
    .complete & {
        display: none;
    }
}

.logoFilled {
    display: none;
    color: $off-white;

    .complete & {
        display: block;
    }
}

.logoBox {
    @include position-100(absolute);
    z-index: 3;
    transform: scale(1.022);
}

.logoBoxPath {
    stroke: $black;
    fill: $off-white;

    .complete & {
        fill: $black;
    }
}

.boxBackImage {
    @include position-100(absolute);
    object-fit: cover;
    opacity: 0;
}

.loaderBar {
    position: absolute;
    bottom: px(20);
    left: 50%;
    width: var(--box-size);
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.loaderBarPercent {
    @include eyebrow;
    margin-bottom: px(10);

    span {
        &:last-child {
            margin-right: px(5);
        }
    }
}

.loaderBarBar {
    width: 100%;
    height: px(2);
    background-color: $black;
    transform: scaleX(0);
}
