/* prettier-ignore */

$heading-line-height: 120%;
$heading-letter-spacing: 0.02em;

@mixin h1-desktop {
    font-weight: 700;
    font-size: px(150);
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

@mixin h1-mobile {
    font-weight: 700;
    font-size: px(105);
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

@mixin h1 {
    @include h1-desktop;

    @include bp(mobile) {
        @include h1-mobile;
    }
}

@mixin h2-desktop {
    font-weight: 500;
    font-size: px(75);
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

@mixin h2-mobile {
    font-weight: 500;
    font-size: px(58);
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

@mixin h2 {
    @include h2-desktop;

    @include bp(mobile) {
        @include h2-mobile;
    }
}

@mixin h3-desktop {
    font-weight: 500;
    font-size: px(48);
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

@mixin h3-mobile {
    font-weight: 500;
    font-size: px(48);
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

@mixin h3 {
    @include h3-desktop;

    @include bp(mobile) {
        @include h3-mobile;
    }
}

@mixin h4-desktop {
    font-weight: 500;
    font-size: px(36);
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

@mixin h4-mobile {
    font-weight: 500;
    font-size: px(24);
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

@mixin h4 {
    @include h4-desktop;

    @include bp(mobile) {
        @include h4-mobile;
    }
}

@mixin h5-desktop {
    font-weight: 500;
    font-size: px(24);
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

@mixin h5-mobile {
    font-weight: 500;
    font-size: px(20);
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}

@mixin h5 {
    @include h5-desktop;

    @include bp(mobile) {
        @include h5-mobile;
    }
}

@mixin body-large-desktop {
    font-weight: 500;
    font-size: px(20);
    line-height: 156%;
    letter-spacing: $heading-letter-spacing;
}

@mixin body-large-mobile {
    font-weight: 500;
    font-size: px(16);
    line-height: 156%;
    letter-spacing: $heading-letter-spacing;
}

@mixin body-large {
    @include body-large-desktop;

    @include bp(mobile) {
        @include body-large-mobile;
    }
}

@mixin body {
    font-weight: 400;
    font-size: px(16);
    line-height: 130%;
    letter-spacing: $heading-letter-spacing;
}

@mixin body-small {
    font-weight: 400;
    font-size: px(13);
    line-height: 130%;
    letter-spacing: $heading-letter-spacing;
}

@mixin eyebrow {
    font-weight: 700;
    font-size: px(14);
    line-height: 130%;
    letter-spacing: $heading-letter-spacing;
    text-transform: uppercase;
}
