@import "shared";

.CookieModal {
    position: fixed;
    bottom: var(--col-size-half);
    left: var(--col-size-half);
    width: px(360);
    padding: px(20);
    background-color: $blue;
    border: 1px solid $black;
    @include z-index(Cookies);
    opacity: 0;

    @include bp(mobile) {
        width: calc(100% - var(--col-size));
    }
}

.text {
    text-align: left;
    @include body-small;
    color: $white;
    margin-bottom: px(15);
}

.buttons {
    display: grid;
    grid-template-columns: minmax(0, 0.5fr) minmax(0, 0.5fr);
    gap: px(15);
}

.button {
    border: 1px solid $white;
    height: px(34);
    @include body-small;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    text-transform: uppercase;
    background-color: transparent;
    color: $white;
    cursor: pointer;
}

.highlight {
    @include transform-center;
}
